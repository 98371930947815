import React from "react"
import Nav from "./nav"
import Logo from "../images/kdk-logo-text.inline.svg"
import { Link } from "gatsby"

class Footer extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <footer className="kdk-footer">
        <div className="container">
          <div className="kdk-footer-content">
            <nav className={`kdk-nav`}>
              <div className="container">
                <div className="kdk-nav-container">
                  <Link to="/">
                    <Logo />
                  </Link>
                  <ul>
                    <li>
                      <Link to="/" activeClassName="active">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/about" activeClassName="active">
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/whats-on" activeClassName="active">
                        What's On
                      </Link>
                    </li>
                    <li>
                      <Link to="/donate" activeClassName="active">
                        Donate
                      </Link>
                    </li>
                    <li>
                      <Link className="btn-default" to="about">
                        Support Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
