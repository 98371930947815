import React from "react"
import { Link } from "gatsby"
import Logo from "../images/kdk-logo-text.inline.svg"
import anime from "animejs"

class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      lastScrollPosY: 0,
      navState: "top",
    }
  }

  isScrollingDown() {
    if (typeof window !== "undefined") {
      return window.scrollY > this.state.lastScrollPosY
    }
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", e => {
        var topBounding = document
          .getElementsByClassName("kdk-top-section")[0]
          .getBoundingClientRect()
        if (window.scrollY >= 1 && this.isScrollingDown()) {
          this.setState({
            navState: "hidden",
          })
        } else if (
          window.scrollY >= 1 &&
          !this.isScrollingDown() &&
          window.scrollY <= topBounding.height
        ) {
          this.setState({
            navState: "visible",
          })
        } else if (
          window.scrollY >= 1 &&
          !this.isScrollingDown() &&
          window.scrollY > topBounding.height
        ) {
          this.setState({
            navState: "visible-bg",
          })
        } else if (window.scrollY === 0) {
          this.setState({
            navState: "top",
          })
        }
        this.setState({
          lastScrollPosY: window.scrollY,
        })
      })
    }
  }
  toggleNav() {
    if (this.state.isOpen) this.closeNav()
    else this.openNav()
  }
  closeNav() {
    if (typeof window !== "undefined")
      window.document.body.classList.remove("prevent-scroll")
    this.setState({
      isOpen: false,
    })
    anime({
      targets: ".nav-mobile-menu ul li",
      opacity: 0,
      top: 20,
      easing: "cubicBezier(0.115, 0.61, 0.255, 1)",
      delay: anime.stagger(100),
    })
  }

  openNav() {
    if (typeof window !== "undefined")
      window.document.body.classList.add("prevent-scroll")
    this.setState({
      isOpen: true,
    })
    anime({
      targets: ".nav-mobile-menu ul li",
      opacity: 1,
      top: 0,
      easing: "cubicBezier(0.115, 0.61, 0.255, 1)",
      delay: anime.stagger(100, {
        start: 1000,
      }),
    })
  }

  render() {
    const { inverse } = this.props
    return (
      <React.Fragment>
        <nav
          className={`kdk-nav kdk-top-nav kdk-nav--${this.state.navState} ${
            this.state.isOpen ? "kdk-nav--open" : ""
          }`}
        >
          <div className="container">
            <div className="kdk-nav-container">
              <Link to="/">
                <Logo />
              </Link>
              <ul>
                <li>
                  <Link to="/" activeClassName="active">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" activeClassName="active">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/whats-on" activeClassName="active">
                    What's On
                  </Link>
                </li>
                <li>
                  <Link to="/donate" activeClassName="active">
                    Donate
                  </Link>
                </li>
                <li>
                  <Link
                    className={inverse ? "btn-purple" : "btn-default"}
                    to="/support"
                  >
                    Support Us
                  </Link>
                </li>
                <li className="top-nav-hamburger">
                  <button
                    onClick={() => this.toggleNav()}
                    className={`hamburger hamburger--collapse ${
                      this.state.isOpen ? "is-active" : ""
                    }`}
                    type="button"
                  >
                    <span className="hamburger-box">
                      <span className="hamburger-inner" />
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div
          className={`nav-mobile-menu ${
            this.state.isOpen ? "nav-mobile-menu--open" : ""
          }`}
        >
          <ul>
            <li>
              <Link fade to="/" onClick={() => this.closeNav()}>
                Home
              </Link>
            </li>
            <li>
              <Link fade to="/about" onClick={() => this.closeNav()}>
                About Us
              </Link>
            </li>
            <li>
              <Link fade to="/whats-on" onClick={() => this.closeNav()}>
                What's On
              </Link>
            </li>
            <li>
              <Link fade to="/donate" onClick={() => this.closeNav()}>
                Donate
              </Link>
            </li>
            <li>
              <Link fade to="/support" onClick={() => this.closeNav()}>
                Support Us
              </Link>
            </li>
          </ul>
        </div>
      </React.Fragment>
    )
  }
}

export default Nav
